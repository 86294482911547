import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import "../../../styles/footer.scss";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import validateMessage from "../../../constants/validateMessages";
import validationRegex from "../../../services/utils/regexUtils";
import { FOOTERITEMS, SOCIALMEDIAICONS } from "../../../constants/footer";
import CONTENT_DATA from "../../../constants/en.json";
import { COLORS } from "../../../constants/colorConstants";
import dsLogo from "../../../assets/edifyLogo.png";
const { BLUE_2, PRIMARY, WHITE_3 } = COLORS;
const {
  _SubscribeForNewsletter_,
  _SubscribeForNewsletterFirst_,
  _SubscribeForNewsletterFirstSecond_,
  _ProductOf_,
  _DyashinTechnosoft_,
  _CONTACTUS_,
  _DyashinTechnosoftLLC_,
  _Middletown_,
  _USA_,
  _ContactNumber_,
  _Gmail_,
  _DyashinTechnosoftPrivateLimited_,
  _DyashinAddress_,
  _PinCode_,
  _contactNumber_,
  _DSEdify_,
  _dsedify_,
  _Copyrights_,
  _AllRightsReserved_,
  _v_,
} = CONTENT_DATA;

let S3_URL = process.env.REACT_APP_DYASHIN_S3_URL;
let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;

const USFlag = S3_URL + "/v3/assests/common/usFlag.png";
const IndiaFlag = S3_URL + "/v3/assests/common/indiaFlag.png";
const callIcon = S3_URL + "/v3/assests/icons/callFooter.png";
const EmailIcon = S3_URL + "/v3/assests/icons/emailFooter.png";
// let dsLogo = EDIFY_S3_URL + "/assetsWebp/footer/edifylogo.webp";

function CustomFooter({ address }) {
  const [subscribeData, setSubscribeData] = useState({ emailId: "" });
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const { addToast } = useToasts();

  const validateEmail = () => {
    let isEmailValid = true;
    if (subscribeData?.emailId === "") {
      isEmailValid = false;

      setEmailErrorMsg(validateMessage.field_required);
    } else if (subscribeData?.emailId.length > 30) {
      isEmailValid = false;
      setEmailErrorMsg(`${validateMessage?.maxLength} 30 characters`);
    } else if (!validationRegex.email.test(subscribeData?.emailId)) {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.email);
    } else {
      isEmailValid = true;
      setEmailErrorMsg("");
    }
    return isEmailValid;
  };

  const handleSubscribe = () => {
    if (validateEmail()) {
      setSubscribeData({ emailId: "" });
      setEmailErrorMsg("");
      addToast("Successfully subscribed to the newsletter!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const handleChange = (e) => {
    setSubscribeData({
      ...subscribeData,
      [e.target.name]: e.target.value,
    });
  };

  const termsCondition = () => {
    const termsDetails = FOOTERITEMS["Know More"].find(
      (tc) => tc.title === "Terms & Conditions"
    );
    return (
      <Link
        className=" text-decoration-none fs-12 fw-400 footerText text-center"
        to={termsDetails.link}
      >
        <>{termsDetails.title}</>
      </Link>
    );
  };
  return (
    <>
      <Grid
        container
        paddingTop={12}
        paddingBottom={2}
        paddingX={4}
        className=" darkBlueBackground"
        position={"relative"}
      >
        <Box className="position-absolute custom-footer-box">
          <Card className="gradient-border p-2">
            <Grid
              container
              className=" my-2 mt-0 align-items-start justify-content-around px-2"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <Box className="d-grid">
                  <Typography className=" fw-600 subscribe-head">
                    {_SubscribeForNewsletter_}
                  </Typography>
                  <Typography className="fs-16">
                    {_SubscribeForNewsletterFirst_} <br />
                    {_SubscribeForNewsletterFirstSecond_}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} className="mt-3">
                <TextField
                  variant="outlined"
                  placeholder="Email Address"
                  name="emailId"
                  value={subscribeData?.emailId}
                  onChange={handleChange}
                  onBlur={validateEmail}
                  error={!!emailErrorMsg}
                  helperText={emailErrorMsg}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <ButtonComponent
                            label="Subscribe Now"
                            onBtnClick={() => {
                              handleSubscribe();
                            }}
                            sx={{
                              backgroundColor: PRIMARY,
                              color: WHITE_3,
                              marginRight: "auto",
                              "&:hover": {
                                backgroundColor: BLUE_2,
                                color: WHITE_3,
                              },
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderWidth: "2px",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>

        <Grid container className="justify-content-between" spacing={3}>
          <Grid item xs={12} md={1.5}>
            <Grid
              container
              spacing={2}
              className="d-flex justify-content-between"
            >
              <Grid item sm={12} md={12}>
                <img
                  loading="lazy"
                  src={dsLogo}
                  alt="company-logo"
                  className="dsedify-logo"
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography className="product-head">
                  {_ProductOf_} <br />
                  {_DyashinTechnosoft_}
                </Typography>
              </Grid>
              <Grid item container xs={12} className="mb-2 icons-border">
                {SOCIALMEDIAICONS.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    xs={3}
                    sm={1}
                    md={5}
                    className="flex-wrap "
                  >
                    <a href={item.link} target="blank">
                      <Tooltip title={item.name} placement="top">
                        <img
                          src={item.icon}
                          alt={item.name}
                          className="footer-icons mb-2"
                        />
                      </Tooltip>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={10.5} spacing={2}>
            {Object.entries(FOOTERITEMS).map(([key, values], idx) => (
              <Grid key={idx} item xs={12} sm={6} md={2}>
                <Typography className="fw-700 fs-16" color="white">
                  {key}
                </Typography>
                {values.map((item, index) => (
                  <Link
                    className="footer-link text-decoration-none"
                    to={item.link}
                    key={index}
                  >
                    <Typography className="fw-400 fs-14 footerLink  text-color">
                      {item.title}
                    </Typography>
                  </Link>
                ))}
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography className="fw-700 fs-16" color="white">
                {_CONTACTUS_}
              </Typography>
            </Grid>
            {/* <Grid item sm={5} md={6}>
              <Grid container>
                <Grid item xs={1} className="d-flex align-items-center">
                  <img loading="lazy" src={USFlag} alt="usflag" />
                </Grid>
                <Grid item xs={11}>
                  <Typography className="fw-400 fs-14 py-2 text-color">
                    {_DyashinTechnosoftLLC_}
                    <br /> {_Middletown_} <br /> {_USA_}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="d-flex align-items-center">
                  <img loading="lazy" src={callIcon} alt="callicon" />
                </Grid>
                <Grid item xs={11}>
                  <Typography className="fw-400 fs-14 py-1 text-color">
                    {_ContactNumber_}
                  </Typography>
                </Grid>
                <Grid item xs={1} className="d-flex align-items-center">
                  <img loading="lazy" src={EmailIcon} alt="emailicon" />
                </Grid>
                <Grid item xs={11}>
                  <Typography className="fw-400 fs-14 py-1 text-color">
                    {_Gmail_}
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item container sm={7} md={8}>
              <Grid item xs={1} className="d-flex align-items-center">
                <img loading="lazy" src={IndiaFlag} alt="indianflag" />
              </Grid>
              <Grid item xs={11}>
                <Typography className="fw-400 fs-14 py-2 text-color">
                  {_DyashinTechnosoftPrivateLimited_} <br />
                  {_DyashinAddress_} <br />
                  {_PinCode_}
                </Typography>
              </Grid>
              <Grid item xs={1} className="d-flex align-items-center">
                <img loading="lazy" src={callIcon} alt="callicon" />
              </Grid>
              <Grid item xs={11}>
                <Typography className="fw-400 fs-14 py-1 text-color">
                  {_contactNumber_}
                </Typography>
              </Grid>
              <Grid item xs={1} className="d-flex align-items-center">
                <img loading="lazy" src={EmailIcon} alt="emailicon" />
              </Grid>
              <Grid item xs={11}>
                <Typography className="fw-400 fs-14 py-1 text-color">
                  {_Gmail_}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box
        className="d-flex justify-content-center position-relative copyright-text"
        paddingY={"8px"}
      >
        <Typography className="fs-12 fw-400 footerText text-center">
          {_DSEdify_}
          <sup>&#174;</sup> &nbsp;&nbsp; | &nbsp;&nbsp; {_dsedify_} &nbsp;&nbsp;
          |&nbsp;&nbsp; {_Copyrights_}
          &nbsp;&nbsp;|&nbsp;&nbsp; {_AllRightsReserved_}{" "}
          &nbsp;&nbsp;|&nbsp;&nbsp;{termsCondition()}&nbsp;&nbsp;|&nbsp;&nbsp;
          {_v_}
        </Typography>
      </Box>
    </>
  );
}

export default CustomFooter;
