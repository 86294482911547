import axios from "axios";

const getFaqsByUserType = async (type) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_DSEDIFY_BACKEND_URL}/api/v2/faq/FAQ/TYPE/${type}`
    );
    return { data: res?.data };
  } catch (err) {
    return { errRes: err?.response?.data || err };
  }
};
export { getFaqsByUserType };
