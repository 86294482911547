import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import { Box } from "@mui/material";
import MultiSelectDropDown from "../MultiSelectDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ExpandMore } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { COLORS } from "../../../constants/colorConstants";
const { WHITE_3, WHITE_4, BLUE_1, BLUE_2 } = COLORS;

function AccordionComponent({
  children,
  AccordionTitle = "Accordion Title",
  isExpand,
  handleChangeExpand,
  headerDropDown = false,
  onDropDownChange = () => {},
  dropDownLabel = "",
  dropDownOptions = [],
  dropDownValue = [],
  AccordionHeadDropDownPlaceholder = "--Select--",
  showExpandAndCollapseIcon = false,
}) {
  return (
    <>
      <Box className="m-3 ">
        <Accordion
          elevation={1}
          sx={{
            borderColor: "lightgray",
          }}
          expanded={isExpand}
          onChange={handleChangeExpand}
        >
          <AccordionSummary
            expandIcon={
              isExpand ? (
                <Tooltip title="Collapse">
                  <IconButton onClick={handleChangeExpand}>
                    {showExpandAndCollapseIcon ? (
                      <ExpandMore fontSize="large" sx={{ color: WHITE_3 }} />
                    ) : (
                      <RemoveIcon fontSize="large" sx={{ color: WHITE_3 }} />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Expand">
                  <IconButton onClick={handleChangeExpand}>
                    {showExpandAndCollapseIcon ? (
                      <ExpandMore
                        fontSize="large"
                        sx={{ color: WHITE_3 }}
                      />
                    ) : (
                      <AddIcon
                        fontSize="large"
                        sx={{
                          color: WHITE_3,
                          transitionDuration: "ease",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              )
            }
            sx={{
              background: isExpand ? BLUE_1 : BLUE_2,
              "&.Mui-focusVisible": {
                background: WHITE_4,
              },
            }}
          >
            <Grid container>
              <Grid item className="d-flex align-items-center gap-1">
                {!showExpandAndCollapseIcon && (
                  <CheckCircleIcon sx={{ color: WHITE_3 }} />
                )}
                <Typography className="fs-22 fw-500" sx={{ color: WHITE_3 }}>
                  {AccordionTitle}
                </Typography>
              </Grid>
              {headerDropDown && (
                <Grid item sm={3}>
                  <MultiSelectDropDown
                    onChange={onDropDownChange}
                    textLabel={dropDownLabel}
                    options={dropDownOptions}
                    value={dropDownValue}
                    placeholder={AccordionHeadDropDownPlaceholder}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "5px !important",
              maxHeight: "100%",
            }}
            className="website-scroll-bar"
          >
            {children}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default AccordionComponent;
