import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  Box,
  Drawer,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import ContactModal from "../contactModal/ContactModal";
import ScrollIndicator from "../ScrollIndicator";
import "../../../styles/adminNavbar.component.scss";
import NavbarEvents from "../navbarEvents";
import { LINKEDPAGES } from "../../../constants/navbarRes";
import { COLORS } from "../../../constants/colorConstants";
import logo from "../../../assets/edifyLogo.png";
const { PRIMARY, BLUE_1, ORANGE_1 } = COLORS;
// let EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
// let logo = EDIFY_S3_URL + "/assetsWebp/navbar/edifylogo.webp";
const edifyAppURL = process.env.REACT_APP_DSEDIFY_UI_URL;
const edifyAppLoginURL = edifyAppURL + "/trainee/login";

export default function NavbarRes() {
  const [connectModal, setconnectModal] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [hoveredPage, setHoveredPage] = useState(null);
  const [open, setState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleMouseLeave = () => setHoveredPage(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    setState(open);
  };

  const handleEnrollMenuItem = (path) => {
    navigate(path);
    handleClose();
    setState(false);
  };

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const isEnquiry = location.pathname === "/enquiry";
  const isLoginPage = location.pathname === "";

  const handleLearningProgMenuItem = (path) => {
    navigate(path);
    setActiveLink(path);
    setHoveredPage(null);
    setState(false);
  };

  const checkForActiveMenuItems = (isActive, page) => {
    if (
      isActive &&
      page?.menuItems?.find((item) => item?.route === location?.pathname)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogin = () => {
    window.open(edifyAppLoginURL, "_blank");
  };
  return (
    <>
      <AppBar className="navbar-main">
        <Container maxWidth="xl">
          <Toolbar className="d-flex justify-content-between align-items-center w-100p">
            <Typography variant="span" className="nav-logo">
              <Link to="/" onClick={() => navigate("/")}>
                <img loading="lazy" src={logo} width={"180px"} alt="logo" />
              </Link>
            </Typography>
            <Box className="nav-res-links" onMouseLeave={handleMouseLeave}>
              {LINKEDPAGES.map((page, index) => (
                <ul className="navbar-nav mb-2 mb-lg-0" key={index}>
                  <li
                    key={page.route}
                    className={`nav-item d-flex justify-content-start align-items-center`}
                    onMouseEnter={() => setHoveredPage(page.route)}
                  >
                    <NavLink
                      onClick={() => {
                        navigate(page.route);
                        setActiveLink(page.route);
                        setState(false);
                      }}
                      className={({ isActive, isPending }) =>
                        isPending
                          ? "pending nav-link fs-14 link-styles"
                          : (isActive && !page.menuItems) ||
                              checkForActiveMenuItems(isActive, page)
                            ? "activeLink nav-link fs-14 link-styles"
                            : "nav-link"
                      }
                      aria-current="page"
                      to={page.route}
                    >
                      {page.label}
                      {page.menuItems && (
                        <>
                          {hoveredPage === page.route ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </>
                      )}
                    </NavLink>
                    {hoveredPage === page.route && page.menuItems && (
                      <Box className="submenu ms-3">
                        {page.menuItems.map((item, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              handleLearningProgMenuItem(item.route);
                              setHoveredPage(null);
                              setState(false);
                            }}
                            className="p-2"
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Box>
                    )}
                  </li>
                </ul>
              ))}
            </Box>
            <Box className="nav-btns" component="div">
              <ButtonComponent
                label="Enroll"
                className="fs-12"
                muiProps="me-2"
                sx={{
                  backgroundColor: PRIMARY,
                  borderRadius: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: BLUE_1,
                  },
                }}
                onBtnClick={handleClick}
                children={<>{!anchorEl ? <ExpandMore /> : <ExpandLess />}</>}
              />
              <Menu
                className="mt-4 p-3 nav-menu"
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/3`
                    )
                  }
                >
                  Experiential Learning Internship program
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/4`
                    )
                  }
                >
                  Professional Development program
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/2`
                    )
                  }
                >
                  Campus Projects Program
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/1`
                    )
                  }
                >
                  Campus Internship Program
                </MenuItem>
              </Menu>
              <ButtonComponent
                label="Enquiry"
                className="fs-12"
                muiProps="me-2"
                sx={{
                  backgroundColor: PRIMARY,
                  borderRadius: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: BLUE_1,
                  },
                }}
                onBtnClick={() => {
                  if (!isEnquiry) {
                    navigate("/enquiry");
                    localStorage.setItem("onclickBatchEnquiry", false);
                  }
                }}
                disabled={isEnquiry}
              />
              <ButtonComponent
                label="Login"
                className="fs-12"
                muiProps="me-2"
                sx={{
                  backgroundColor: PRIMARY,
                  borderRadius: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: BLUE_1,
                  },
                }}
                onBtnClick={() => {
                  handleLogin();
                }}
              />
            </Box>
            <IconButton
              className="nav-icon-btn mr-2"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <Box className="nav-close-icon">
                <IconButton className="mb-2">
                  <CloseIcon onClick={toggleDrawer(false)} />
                </IconButton>
                <Divider className="mb-2" />
                <Box className="nav-link-page">
                  {LINKEDPAGES.map((page, index) => (
                    <ul className="navbar-nav mb-lg-0" key={index}>
                      <li
                        key={page.route}
                        className={`nav-item d-flex justify-content-start align-items-center`}
                        onMouseEnter={() => setHoveredPage(page.route)}
                      >
                        <NavLink
                          onClick={() => {
                            navigate(page.route);
                            setActiveLink(page.route);
                            setState(false);
                          }}
                          className={({ isActive, isPending }) =>
                            isPending
                              ? "pending nav-link fs-14"
                              : (isActive && !page.menuItems) ||
                                  checkForActiveMenuItems(isActive, page)
                                ? "activeLink nav-link fs-14"
                                : "nav-link"
                          }
                          aria-current="page"
                          to={page.route}
                        >
                          {page.label}
                          {page.menuItems && (
                            <>
                              {hoveredPage === page.route ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </>
                          )}
                        </NavLink>
                        {hoveredPage === page.route && page.menuItems && (
                          <Box className="submenu ms-3">
                            {page.menuItems.map((item, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  handleLearningProgMenuItem(item.route);
                                  setHoveredPage(null);
                                  setState(false);
                                }}
                                className="p-2"
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Box>
                        )}
                      </li>
                    </ul>
                  ))}
                </Box>
                <Box className="d-grid  mt-2">
                  <ButtonComponent
                    label="Enroll"
                    className="fs-12"
                    muiProps=" mb-2"
                    sx={{
                      backgroundColor: PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                    onBtnClick={handleClick}
                    children={
                      <>{!anchorEl ? <ExpandMore /> : <ExpandLess />}</>
                    }
                    fullWidth
                  />
                  <Menu
                    className="mt-4 p-3"
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/3`
                        )
                      }
                    >
                      Experiential Learning Internship program
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/4`
                        )
                      }
                    >
                      Professional Development program
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/2`
                        )
                      }
                    >
                      Campus Projects Program
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_DSEDIFY_UI_URL}/programs/enroll/1`
                        )
                      }
                    >
                      Campus Internship Program
                    </MenuItem>
                  </Menu>
                  <ButtonComponent
                    label="Enquiry"
                    className="fs-12"
                    muiProps=" mb-2"
                    sx={{
                      backgroundColor: PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                    onBtnClick={(event) => {
                      if (!isEnquiry) navigate("/enquiry");
                      toggleDrawer(false)(event);
                    }}
                    disabled={isEnquiry}
                    fullWidth
                  />
                  <ButtonComponent
                    label="Login"
                    className="fs-12"
                    muiProps=" mb-2"
                    sx={{
                      backgroundColor: PRIMARY,
                      borderRadius: "20px",
                      color: "white",
                      "&:hover": {
                        backgroundColor: BLUE_1,
                      },
                    }}
                    onBtnClick={() => {
                      handleLogin();
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
            </Drawer>
          </Toolbar>
        </Container>
        <Box>
          <NavbarEvents />
          <ScrollIndicator color={ORANGE_1} />
        </Box>
      </AppBar>
      <ContactModal
        connectModal={connectModal}
        setconnectModal={setconnectModal}
      />
    </>
  );
}
