import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import AccordionComponent from "../../atoms/AccordionComponent";
import { getFaqsByUserType } from "../../../services/faqs/Faqs";
import FAQS_DATA from "../../../constants/en.json";

const { Frequently_Asked_Questions, General, No_FAQs_Available } = FAQS_DATA.FAQS;

function Faqs() {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    getFaqsByUserTypeData();
  }, []);

  const getFaqsByUserTypeData = async () => {
    const { data } = await getFaqsByUserType("ALL");
    if (data?.data?.length) {
      console.log(data.data);
      setFaqs(data.data);
    }
  };

  const getAllFaqDetails = useCallback(() => {
    return faqs.map(({ id, faqTitle, faqDescription }, index) => (
      <Box
        key={id}
        className={`${index < faqs.length - 1 ? "border-bottom" : ""}`}
      >
        <AccordionComponent AccordionTitle={faqTitle} showExpandAndCollapseIcon>
          <Typography>{faqDescription}</Typography>
        </AccordionComponent>
      </Box>
    ));
  }, [faqs]);

  return (
    <>
      <Box className="bg-img d-flex justify-content-center align-items-center w-100">
        <Typography className="text-white fs-50 fw-700 faq-text">
          {Frequently_Asked_Questions}
        </Typography>
      </Box>
      <Grid container spacing={4} className="px-3 mt-2">
        {faqs.length ? (
          <>
            <Grid item xs={12} md={4} className="flex-column">
              <Grid item className="mb-3">
                <Button
                  variant="outlined"
                  fullWidth
                  className="faq-button fw-bold text-capitalize"
                >
                  {General}
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography variant="h5" className="fw-bold ps-3">
                {General}
              </Typography>
              <Grid
                item
                xs={12}
                className="faq-content-container overflow-y-auto h-200p"
              >
                {getAllFaqDetails()}
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid 
            item 
            xs={12} 
            className="d-flex align-items-center justify-content-center"
          >
            <Typography
              variant="h5"
              className="text-center fw-bold px-5 mt-2 mb-5 no-faqs-message"
            >
             {No_FAQs_Available}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Faqs;
