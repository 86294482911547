import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputBoxComponent from "../../atoms/InputBoxComponent";
import SimpleDropdown from "../../atoms/SimpleDropDownComponent";
import ButtonComponent from "../../atoms/ButtonComponent/ButtonComponent";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import validateMessage from "../../../constants/validateMessages";
import validationRegex from "../../../services/utils/regexUtils";
import { useToasts } from "react-toast-notifications";
import YopDatePickerComponent from "../../atoms/YopDatePickerComponent/YopDatePickerComponent";
import { saveEnquiryDetails } from "../../../services/enquiry/enquiry";
import { getDateformatted } from "../../../commonFunction";
import {
  DEGREEOPTION,
  SEMESTEROPTIONS,
  INTRESTEDOPTIONS,
  TECHNOLOGYOPTIONS,
} from "../../../constants/globalConstant";
import ENQUIRY_DATA from "../../../constants/en.json";
import { COLORS } from "../../../constants/colorConstants";
const { RED_1, WHITE_3, PRIMARY, BLUE_1 } = COLORS;

const { Candidate_Enquiry } = ENQUIRY_DATA;

function Enquiry() {
  const [enquiryData, setEnquiryData] = useState({
    fullName: "",
    emailId: "",
    contactNo: "",
    alternativeNo: "",
    collegeName: "",
    degree: "",
    semester: "",
    yearOfPassing: null,
    interestedIn: [],
    technologies: [],
  });
  const [intrests, setIntrests] = useState([]);
  const [technologyIntrests, setTechnologyIntrests] = useState([]);
  const [fullNameErrorMsg, setFullNameErrorMsg] = useState("");
  const [alternateNumberErrorMsg, setAlternateNumberErrorMsg] = useState("");
  const [contactNumberErrorMsg, setContactNumberErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [collegeNameErrorMsg, setCollegeNameErrorMsg] = useState("");
  const [degreeErrorMsg, setdegreeErrorMsg] = useState("");
  const [degreeDescription, setdegreeDescription] = useState(false);
  const [batchData, setBatchData] = useState({
    batchName: "",
    enquiryBatch: "",
  });
  const { addToast } = useToasts();

  const handleChange = (e) => {
    setEnquiryData({ ...enquiryData, [e.target.name]: e.target.value });
  };

  const handleDropdownChange = (selectedOption, key) => {
    const selectedId = selectedOption?.id || "";
    if (selectedId === "Others") {
      setdegreeDescription(true);
    } else {
      setdegreeDescription(false);
    }
    const updatedRegisterData = { ...enquiryData };
    updatedRegisterData[key] = selectedId;
    setEnquiryData(updatedRegisterData);
  };

  const handleChangeDate = (val) => {
    let data = getDateformatted(val);
    setEnquiryData({
      ...enquiryData,
      yearOfPassing: data,
    });
  };

  const handleMultiSelectChange = (field, selected, type) => {
    if (type === "multi") {
      const selectedIds = selected.map((item) => item.id);
      if (field === "interestedIn") {
        setIntrests(selectedIds);
      } else {
        setTechnologyIntrests(selectedIds);
      }
    }
  };

  const validateFullName = () => {
    let isFullNameValid = true;
    if (enquiryData?.fullName === "") {
      isFullNameValid = false;
      setFullNameErrorMsg(validateMessage?.field_required);
    } else if (enquiryData?.fullName.length > 30) {
      isFullNameValid = false;
      setFullNameErrorMsg(`${validateMessage?.maxLength}`);
    } else if (!validationRegex?.name.test(enquiryData?.fullName)) {
      isFullNameValid = false;
      setFullNameErrorMsg(validateMessage?.alphabets);
    } else {
      isFullNameValid = true;
      setFullNameErrorMsg("");
    }
    return isFullNameValid;
  };

  const validateContactNumber = () => {
    let ismNumberValid = true;
    if (enquiryData?.contactNo === "") {
      ismNumberValid = false;
      setContactNumberErrorMsg(validateMessage.field_required);
    } else if (!validationRegex?.mobile.test(enquiryData?.contactNo)) {
      ismNumberValid = false;
      setContactNumberErrorMsg(validateMessage.mobile);
    } else {
      ismNumberValid = true;
      setContactNumberErrorMsg("");
    }
    return ismNumberValid;
  };

  const validateEmail = () => {
    let isEmailValid = true;
    if (enquiryData?.emailId === "") {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.field_required);
    } else if (enquiryData?.emailId.length > 30) {
      isEmailValid = false;
      setEmailErrorMsg(`${validateMessage?.maxLength}`);
    } else if (!validationRegex.email.test(enquiryData?.emailId)) {
      isEmailValid = false;
      setEmailErrorMsg(validateMessage.email);
    } else {
      isEmailValid = true;
      setEmailErrorMsg("");
    }
    return isEmailValid;
  };

  const validateCollegeName = () => {
    let iscNameValid = true;
    if (enquiryData?.collegeName === "") {
      iscNameValid = false;
      setCollegeNameErrorMsg(validateMessage.field_required);
    } else if (
      enquiryData?.collegeName !== "" &&
      enquiryData?.collegeName.length > 150
    ) {
      iscNameValid = false;
      setCollegeNameErrorMsg(`${validateMessage?.maxLength} 150 characters`);
    } else if (
      enquiryData?.collegeName !== "" &&
      !validationRegex?.name.test(enquiryData?.collegeName)
    ) {
      iscNameValid = false;
      setCollegeNameErrorMsg(validateMessage?.alphabets);
    } else {
      iscNameValid = true;
      setCollegeNameErrorMsg("");
    }
    return iscNameValid;
  };

  const validateAlternateContactNumber = () => {
    let isAlternateNumberValid = true;

    if (!enquiryData?.alternativeNo) {
    } else if (enquiryData?.alternativeNo === enquiryData?.contactNo) {
      isAlternateNumberValid = false;
      setAlternateNumberErrorMsg(validateMessage.alternativeNumber);
    } else if (!validationRegex?.mobile.test(enquiryData?.alternativeNo)) {
      isAlternateNumberValid = false;
      setAlternateNumberErrorMsg(validateMessage.mobile);
    } else {
      setAlternateNumberErrorMsg("");
    }
    return isAlternateNumberValid;
  };

  const validateDegree = () => {
    let isDegreeValid = true;
    if (enquiryData?.degree === "") {
      isDegreeValid = false;
      setdegreeErrorMsg(validateMessage?.field_required);
    } else {
      isDegreeValid = true;
      setdegreeErrorMsg("");
    }
    return isDegreeValid;
  };
  const handleClearForm = () => {
    setEnquiryData({
      fullName: "",
      emailId: "",
      contactNo: "",
      alternativeNo: "",
      collegeName: "",
      degree: "",
      semester: "",
      yearOfPassing: null,
      interestedIn: [],
      technologies: [],
    });
    setIntrests([]);
    setTechnologyIntrests([]);
    setFullNameErrorMsg("");
    setEmailErrorMsg("");
    setContactNumberErrorMsg("");
    setCollegeNameErrorMsg("");
    setdegreeErrorMsg("");
  };

  const validateRegisterData = () => {
    let isNameValid = validateFullName();
    let isEmailValid = validateEmail();
    let isContactNumberValid = validateContactNumber();
    let isCollegeNameValid = validateCollegeName();
    let isDegreeValid = validateDegree();

    if (
      isNameValid &&
      isEmailValid &&
      isContactNumberValid &&
      isCollegeNameValid &&
      isDegreeValid
    ) {
      saveData();
    }
  };

  useEffect(() => {
    setEnquiryData((prev) => ({ ...prev, interestedIn: intrests }));
  }, [intrests]);
  useEffect(() => {
    setEnquiryData((prev) => ({ ...prev, technologies: technologyIntrests }));
  }, [technologyIntrests]);

  useEffect(() => {
    const storedBatchDetails = localStorage.getItem("selectedBatchDetails");
    if (storedBatchDetails) {
      const batchDetails = JSON.parse(storedBatchDetails);
      setEnquiryData((prev) => ({
        ...prev,
        interestedIn: [batchDetails?.batchId],
      }));
      setBatchData((prev) => ({
        ...prev,
        batchName: batchDetails?.batchName,
      }));
      localStorage.removeItem("selectedBatchDetails");
    }
    const storedOnclickBatchEnquiry = localStorage.getItem(
      "onclickBatchEnquiry"
    );
    if (storedOnclickBatchEnquiry) {
      const onclickBatchEnquiry = JSON.parse(storedOnclickBatchEnquiry);
      setBatchData((prev) => ({
        ...prev,
        enquiryBatch: onclickBatchEnquiry,
      }));
    }
  }, []);

  const saveData = async () => {
    const { data, errRes } = await saveEnquiryDetails(enquiryData);

    if (data) {
      if (data.error) {
        addToast(data.message, { apperance: "error" });
      } else {
        addToast(data.message, { apperance: "success" });
        handleClearForm();
      }
    } else if (errRes) {
    }
  };

  return (
    <>
      <Box className="px-5 footer">
        <Paper elevation={3} className="position-relative">
          <Grid container className="mt-4 px-3" spacing={3}>
            <Grid item xs={12}>
              <Typography className="pt-3 fs-24 fw-600 text-center">
                {Candidate_Enquiry}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBoxComponent
                textLabel="Full Name"
                placeholder="Enter your full name"
                name="fullName"
                value={enquiryData?.fullName}
                onChange={handleChange}
                onBlur={validateFullName}
                errorText={fullNameErrorMsg}
                required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBoxComponent
                textLabel="Email ID"
                placeholder="Enter your Email ID"
                name="emailId"
                value={enquiryData?.emailId}
                onChange={handleChange}
                required="true"
                onBlur={validateEmail}
                errorText={emailErrorMsg}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBoxComponent
                textLabel="Contact Number"
                placeholder="Enter your contact number"
                name="contactNo"
                value={enquiryData?.contactNo}
                onChange={handleChange}
                onBlur={validateContactNumber}
                errorText={contactNumberErrorMsg}
                required="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBoxComponent
                textLabel="Alternate Number"
                placeholder="Enter your alternate contact number"
                name="alternativeNo"
                onChange={handleChange}
                value={enquiryData?.alternativeNo}
                onBlur={validateAlternateContactNumber}
                errorText={alternateNumberErrorMsg}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBoxComponent
                textLabel="College Name"
                placeholder="Enter your college name"
                name="collegeName"
                value={enquiryData?.collegeName}
                onChange={handleChange}
                required="true"
                onBlur={validateCollegeName}
                errorText={collegeNameErrorMsg}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SimpleDropdown
                textLabel="Degree"
                options={DEGREEOPTION}
                name="degree"
                value={
                  DEGREEOPTION.find(
                    (option) => option.id === enquiryData?.degree
                  ) || ""
                }
                onChange={(selectedOpt) =>
                  handleDropdownChange(selectedOpt, "degree")
                }
                onBlur={validateDegree}
                errorText={degreeErrorMsg}
                required="true"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SimpleDropdown
                textLabel="Semester"
                options={SEMESTEROPTIONS}
                name="semester"
                value={
                  SEMESTEROPTIONS.find(
                    (option) => option.id === enquiryData?.semester
                  ) || ""
                }
                onChange={(selectedOpt) =>
                  handleDropdownChange(selectedOpt, "semester")
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <YopDatePickerComponent
                textLabel="Year of Passing"
                name="yearOfPassing"
                value={enquiryData?.yearOfPassing}
                onChange={handleChangeDate}
                disableFuture="true"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {batchData?.enquiryBatch ? (
                <InputBoxComponent
                  textLabel="Interested In"
                  value={batchData?.batchName}
                  readOnly
                />
              ) : (
                <MultiSelectDropDown
                  textLabel="Program Interested In"
                  name="interestedIn"
                  value={INTRESTEDOPTIONS.filter((item) =>
                    intrests.includes(item.id)
                  )}
                  options={INTRESTEDOPTIONS}
                  onChange={(e) =>
                    handleMultiSelectChange("interestedIn", e, "multi")
                  }
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <MultiSelectDropDown
                textLabel="Technology"
                name="program"
                options={TECHNOLOGYOPTIONS}
                value={TECHNOLOGYOPTIONS.filter((item) =>
                  technologyIntrests.includes(item.id)
                )}
                onChange={(e) =>
                  handleMultiSelectChange("technologies", e, "multi")
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              className="d-flex justify-content-end align-items-center p-3"
            >
              <ButtonComponent
                label="Clear All"
                className="fs-12"
                onBtnClick={() => {
                  handleClearForm();
                }}
                sx={{
                  marginRight: "10px",
                  backgroundColor: RED_1,
                  borderRadius: "20px",
                  color: WHITE_3,
                  "&:hover": {
                    backgroundColor: RED_1,
                  },
                }}
              />
              <ButtonComponent
                label="Submit"
                className="fs-12"
                sx={{
                  backgroundColor: PRIMARY,
                  borderRadius: "20px",
                  color: WHITE_3,

                  "&:hover": {
                    backgroundColor: BLUE_1,
                  },
                }}
                onBtnClick={() => {
                  validateRegisterData();
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default Enquiry;
