import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CustomNavbar from "./components/molecules/navbar";
import HomePage from "./components/pages/home/HomePage";
import CustomFooter from "./components/molecules/footer/Footer";
import ScrollToTop from "./components/pages/ScrollToTop";
import { ToastProvider } from "react-toast-notifications";
import CustomToast from "./components/CustomToast/CustomToast";
import "./App.scss";
import "./styles/font.scss";
import "./styles/color.scss";
import "./styles/programs.scss";
import "./styles/chatbot.scss";
import "./styles/global.scss";
import "./styles/connectwithus.scss";
import "./styles/campusProgram.scss";
import "./styles/offCampusProgram.scss";
import "./styles/corporatePrograms.scss";
import "./styles/cvform.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/home.scss";
import "./styles/technology.scss";
import "./styles/navbarComponent.scss";
import "./styles/stepper.scss";
import "./styles/footer.scss";
import "./styles/tabsData.scss";
import "./styles/joinourteam.scss";
import "./styles/popOver.scss";
import "./styles/batchCardComponent.scss";
import "./styles/eventCardComponent.scss";
import "./styles/aboutCarrearCard.scss";
import "./styles/chatBotHeader.scss";
import "./styles/carouselNew.scss";
import "./styles/enquiry.scss";
import "./styles/faqs.scss";
import ConnectWithUs from "./components/pages/connect/ConnectWithUs";
import PdfRedirect from "./components/molecules/pdfRedirect";
import OffCampusPrograms from "./components/pages/offCampusPrograms/OffCampusPrograms";
import CampusPrograms from "./components/pages/campusPrograms/CampusPrograms";
import CorporatePrograms from "./components/pages/corporatePrograms/CorporatePrograms";
import TechnologyPage from "./components/pages/TechnologyComponent/TechnologyPage";
import Careers from "./components/pages/Careers";
import { useState } from "react";
import ChatbotComp from "./chatbot/Chatbot";
import Helplinebutton from "./components/atoms/helplineButton";
import Enquiry from "./components/pages/enquiry/Enquiry";
import Faqs from "./components/pages/faqs/Faqs";
const EDIFY_S3_URL = process.env.REACT_APP_DSEDIFY_S3_URL;
let termsConditionURL =
  EDIFY_S3_URL + "/policies/TERMS+AND+CONDITIONS-DSEDIFY.pdf";
let privacyURL = EDIFY_S3_URL + "/policies/Privacy+Policy-DSEDIFY.pdf";
let refundURL = EDIFY_S3_URL + "/policies/Refund+Policy-DSEDIFY+.pdf";

function App() {
  const [showChatbot, setshowChatbot] = useState(false);
  return (
    <>
      <Helplinebutton
        right="3%"
        bottom={"5%"}
        onChatClick={() => {
          setshowChatbot(true);
        }}
      />

      {showChatbot && (
        <div className="chatbot-container">
          <ChatbotComp
            onClose={() => {
              setshowChatbot(false);
            }}
          />
        </div>
      )}

      <BrowserRouter>
        <ToastProvider
          components={{ Toast: CustomToast }}
          placement="top-right"
          autoDismissTimeout={3500}
          autoDismiss
          id="toaster"
          transitionDuration={4}
        >
          <div id="loader" className="d-none">
            <div class="loader"></div>
          </div>
          <div>
            <ScrollToTop />
            <CustomNavbar />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route
                path="/campus-programs/:section?"
                element={<CampusPrograms />}
              />
              <Route
                path="/off-campus-programs/:section?"
                element={<OffCampusPrograms />}
              />
              <Route
                path="/corporate-programs/:section?"
                element={<CorporatePrograms />}
              />
              <Route
                path="/technology/:section?"
                element={<TechnologyPage />}
              />

              <Route path="/enquiry" element={<Enquiry />} />
              <Route path="/contact-us" element={<ConnectWithUs />} />
              <Route path="/careers" element={<Careers />} />

              <Route
                path="/privacy-policy"
                element={<PdfRedirect pdfUrl={privacyURL} />}
              />
              <Route
                path="/refund-policy"
                element={<PdfRedirect pdfUrl={refundURL} />}
              />
              <Route
                path="/terms-and-conditions"
                element={<PdfRedirect pdfUrl={termsConditionURL} />}
              />
              <Route path="/*" element={<Navigate to="/" />} />
              <Route path="/faq" element={<Faqs />} />
            </Routes>
            <CustomFooter />
          </div>
        </ToastProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
